/* stylelint-disable selector-class-pattern */
@import '../../variables';
@import '../../responsive';
@import '../textTypography';

.container {
  margin-right: 12px;

  .container__avatar {
    cursor: pointer;
    background-color: $purple-lightest;
    border: 1px solid $primary;
    color: $primary;
    font-size: 14px !important;

    span {
      line-height: 22px !important;
      transform: scale(1) translateX(-50%) translateY(-50%) !important;
      top: 50% !important;
    }
  }
}

.tooltip {
  position: fixed !important;
  top: 53px !important;
}

.user_nav__dropdown {
  z-index: 1051 !important;
  top: 53px !important;
  position: fixed !important;

  & > ul[class ~= 'ant-menu-root'] {
    padding-top: 8px !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 12px 0 #00000040;

    li[class ~= 'ant-menu-item-group'] {
      border-bottom: 1px solid #d0d1d6;
      margin-bottom: 8px;
      padding-bottom: 8px;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }

      div[class ~= 'ant-menu-item-group-title'] {
        height: 32px;
        padding: 0 12px !important;
        display: flex;
        align-items: center;
        color: $text-blue-dark-light;

        @include textTypography(Inter, 12px, normal, 600, 20px, 0, left);
      }

      &:last-child {
        border-bottom: none;

        div[class ~= 'ant-menu-item-group-title'] {
          display: none;
        }
      }

      li[class ~= 'ant-menu-item'] {
        height: 32px;
        padding: 0 20px !important;
        margin: 0 !important;
        display: flex;
        align-items: center;

        a {

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

.userDropdown {

  :global .anticon + span {
    width: 100%;
  }
}

.userDropdownMenuItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global .anticon {
    color: $grey-7;
    margin: 0 !important;
    margin-left: 16px !important;

    svg {

      path {
        fill: #d0d1d6;

        @include responsive(pc) {
          fill: $grey-7;
        }
      }
    }

    .anticon {
      margin: 0;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
