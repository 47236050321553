.menu {

  :global {

    .ant-dropdown-menu-item-group {
      min-width: 194px;
    }

    .ant-dropdown-menu-item-group-title {
      color: var(--gray-7);
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
    }

    .ant-dropdown-menu-item-group-list {
      margin: 0;
      padding: 0;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item-group-list{
      margin: 0;
    }


    .ant-dropdown-menu-title-content {
      flex: 1;
      padding: 12px;
      display: flex;
      align-items: center;

      a {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.dropdownBtn{
  // background-color: red;
}

.spacer {
  width: 18px;
}

