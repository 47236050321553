.containerAvatar {
  border: 1px solid var(--blue-3) !important;
  color: var(--ant-primary-color) !important;
  background-color: var(--blue-1) !important;

  :global .ant-avatar-string {
    font-size: 14px;
  }
}

.container {
  display: flex;
  align-items: center;
  gap: 4px;

  .containerAvatar {
    cursor: pointer;
  }
}

.goldBadge {
  margin-right: 6px !important;
}

.displayName {
  padding: 4px 0 !important;

  &:focus, &:hover {
    background: transparent !important;
  }
}

.downIconWrapper {
  display: flex;
}

.downIcon {
  cursor: pointer;
}

.userNavDropdown {
  z-index: 1051;
  position: fixed;

  &:global.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {

    .ant-dropdown-arrow {

      &::before {
        background: var(--blue-1) ;
      }
    }
  }
}

.userMenuContainer {
  border-radius: 4px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  box-shadow: var(--box-shadow-dropdown);
}


.userMenuHeader {
  min-height: 200px;
  min-width: 320px;
  padding: 24px;
  background-color: var(--gray-1);
  background-image: var(--gradient-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.menuHeaderName {
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-9);
}

.menuHeaderEmail {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-8);
}

.menuItemGroup {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 4px;

  :global(.ant-dropdown-menu-item-group-title) {
    color: var(--gray-8);
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
  }
}

.logoutItem {
  margin-top: 4px !important;
}

.userDropdown {
  padding-top: 0 !important;
  border-right: 0;
  overflow: hidden;
  z-index: 1;
  border-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;


  :global .anticon + span {
    width: 100%;
  }
}

.userDropdownMenuItem {
  color: inherit !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global .anticon {
    color: var(--gray-7) !important;
    margin: 0;
    margin-left: 16px;

    svg {

      path {
        fill: var(--gray-7);
      }
    }

    .anticon {
      margin: 0;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}



