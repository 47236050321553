.searchPanelWrapper {
  padding: 24px 12px;
  background-color: white;

  // box-shadow: var(--box-shadow-full-dropdown);
  width: 100%;
}

.header{
  align-items: center;
  display: flex;

  // max-width: 760px;
  // margin: 0 auto;
  margin-bottom: 16px;

  :global {

    h2.ant-typography, div.ant-typography-h2, div.ant-typography-h2> textarea, .ant-typography h2,.anticon{
      margin-bottom: 0;
      margin-top: 0;
      font-size: 24px;
      line-height: 32px;
      color: var(--grey-9);
      @media ( max-width: 768px ) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  

}

// .headerTitle,.headerIcon{
// }

.headerTitle{
  margin-bottom: 0;
  margin-top: 0;
}

.search{
  position: relative;
}

.resultsWrapper{
  overflow: hidden;
  margin: auto;

  mark{
    background-color: transparent;
    text-decoration: underline;
  }

  :global{


    .ais-SearchBox-form{
      display: flex;
      position: relative;

      // padding: 0 8px;
      margin-bottom: 16px;
    }

    .ais-SearchBox-input{
      font-size: 16px;
      line-height: 24px;
      padding: 8px 12px;
      border-radius: 4px;
      flex-grow: 1;
      border: 1px solid var(--grey-5);
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:focus{
        border-color: var(--primary-color);
        box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
        border-inline-end-width: 1px;
        outline: 0;
      }
    }

    .ais-Hits-list{
      margin: 0;
      padding: 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      column-gap: 16px;
      row-gap: 16px;
    }

    .ais-Hits-item{
      max-width: 100%;
      grid-column: inherit;
    }

    .ais-Highlight-highlighted{
      font-weight: bold;
      color: var(--primary-color);
    }

    .ais-SearchBox-submit{
      background-color: var(--primary-color);
      border: none;
      outline: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 8px 16px;
    }

    .ais-SearchBox-reset{
      display: none;
    }

    .ais-SearchBox-loadingIndicator{
      position: absolute;
      z-index: 2;
      right: 110px;
      top: 20px;
    }

    .ais-Breadcrumb-list,
    .ais-Breadcrumb-item,
    .ais-Pagination-list,
    .ais-RangeInput-form,
    .ais-RatingMenu-link,
    .ais-PoweredBy {
      margin: 16px auto 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center; 
      justify-content: center;
    }

    .ais-Pagination-link {
      padding: 0.3rem 0.6rem;
      display: block;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
      transition: background-color 0.2s ease-out;
      margin: 0 8px;
    }

    .ais-Breadcrumb-link, .ais-HierarchicalMenu-link, .ais-Menu-link, .ais-Pagination-link, .ais-RatingMenu-link {
      color: var(--primary-color);
      transition: color 0.2s ease-out;
    }

    .ais-Pagination-item--disabled .ais-Pagination-link{
      opacity: 0.6;
      cursor: not-allowed;
      color: var(--grey-9);
      border: 1px solid var(--grey-9);
    }




    .ais-Pagination-item.ais-Pagination-item--page.ais-Pagination-item--selected .ais-Pagination-link{
      background-color: var(--primary-color);
      color: white;
    }
  }
}

.coverWrapper{
  background-color: #efeff1;

  img{
    width: 100%;

    // height: 100%;
    object-fit: cover;
    display: block;
  }
}

.searchButton{
  color: white;
}

.sectionTitle{
  text-align: right;
  font-weight: normal;
  color: var(--grey-8);
}