@import '../../variables';
@import '../textTypography';

.user__nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .nav__head {
    padding: 16px;
    border-top: 1px solid $text-blue-dark-light;

    .user__detail {
      display: flex;
      align-items: center;

      .user__name {
        color: white;
        margin-bottom: 0;

        @include textTypography(Inter, 16px, normal, 600, 24px, 0, left);
      }
    }

    .user__trail {
      display: flex;
      align-items: center;
      margin-top: 16px;

      .user__get_gold {
        padding: 5px 16px;
        margin-right: 8px;
        text-transform: unset;

        @include textTypography(Inter, 14px, normal, 400, 22px, 0, center);
      }
    }
  }

  .nav__menu {
    list-style: none;
    color: #d0d1d6;
    padding: 0;
    margin-bottom: 8px;

    .menu__item {

      :global .anticon {

        svg {

          path {
            fill: #d0d1d6;
          }
        }
      }

      &___border {
        border-bottom: 1px solid $text-blue-dark-light;
      }

      &:first-child {
        border-top: 1px solid $text-blue-dark-light;
      }

      a {
        padding: 8px 16px;
        display: flex;
        align-items: center;

        @include textTypography(Inter, 14px, normal, 400, 22px, 0, left);

        color: inherit;

        &:hover {
          color: #d0d1d6;
        }

        span {
          margin-right: 8px;
        }

      }
    }
  }
}
