.helpCenterDrawer {

  :global(.ant-drawer-body) {
    padding: 0;
  }
}

.helpCenterDropdown {
  position: fixed;
  top: 43px !important;
  z-index: 9999;

  :global(.ant-dropdown-menu-item-group-list) {
    margin: 0;
  }
}

.helpCenterMenu {
  position: relative;
  max-width: 424px;
  overflow-y: auto;
  overflow-x: hidden;
}

.helpCenterMenuDrawer {

  .menuItem {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }
}

.helpCenterMenuDropdown {
  max-height: calc(100vh - 100px);

  :global(.ant-dropdown-menu-item-group-title) {
    padding: 12px 20px;
    color: var(--gray-8);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 8px;
  }

  .menuItem {

    :global(.ant-dropdown-menu-item-icon) {
      font-size: 20px;
      align-self: flex-start;
      padding-top: 12px;
      margin-right: 16px;
    }

    .itemWrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        content: '';
        bottom: -5px;
        left: 0;
        position: absolute;
        border-bottom: 1px solid var(--border-color);
        width: 100%;
      }
    }
  }

  .menuItem:last-of-type {

    .itemWrapper::after {
      border-bottom: 0;
    }
  }

  .itemContent {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    padding-right: 16px;

    .itemTitle {
      font-size: 14px;
      line-height: 22px;
      color: var(--gray-8);
      margin-bottom: 2px;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 20px;
      color: var(--gray-7);
    }
  }
}

