/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */

.headerWrapper {
  padding: 0 30px;
  min-height: var(--tbd-header-height);
  display: flex;
  align-items: center;
  background-color: var(--gray-1);
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: transform 0.3s;
  box-shadow: 0 1px 0 var(--border-color);
}

.headerScrollDown {
  transform: translateY(-101%);
}

.headerScrollUp {
  transform: translateY(0);
}

.navWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  @media ( max-width:991px ) {
    justify-content: space-between;
  }
  

  :global{

    .ant-space-compact-block{
      width: 200px;
    }
  }
}

.headerMenu {
  display: flex;
  align-items: center;
  width: 100%;

  :global{

    .ant-menu-title-content{
      font-size: 16px;
      color: var(--gray-9);
    }
  }

  @media ( max-width:991px ) {
    display: none;
  }
}

.headerMenuItemBadge {
  margin-left: 8px;
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.leftMenuDesktop {
  --menu-spacing-horizontal: 12px;

  flex: 1;
  flex-grow: 1;
  border-bottom: 0;
  margin-left: 24px;

  :global {

    .ant-menu-overflow-item {
      padding: 6px var(--menu-spacing-horizontal) !important;
      font-size: 16px;
      color: var(--gray-9);

      .ant-dropdown-button> .anticon.anticon-down, .ant-dropdown-link> .anticon.anticon-down, .ant-dropdown-trigger> .anticon.anticon-down {
        font-size: 12px;
      }
    }

    .ant-menu-title-content {
      display: flex;
      height: 100%;
    }
  }

  & > :global(.ant-menu-item::after),
  & > :global(.ant-menu-submenu::after) {
    right: var(--menu-spacing-horizontal);
    left: var(--menu-spacing-horizontal);
  }
}

.headerTransparent {
  // TBA Landing background
  background-color: #d1e8ff;
  box-shadow: unset;

  .leftMenuDesktop {
    background-color: inherit;
    transition: background 0s;
  }
}

.rightMenuDesktop {
  display: flex;
  gap: 2px;
  align-items: center;
}

.loginButtonDesktop {
  text-transform: uppercase;
  color: var(--primary-color);
}

.loginButtonDrawer {
  text-transform: uppercase;
  color: var(--gray-1);
  border: 1px solid var(--gray-1);
}

.upgradeBtn {

  &, &:hover, &:focus, &:active {
    background: var(--gradient);
    color: var(--gray-1);
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px 16px;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
  }
}

.subMenuDropdown {
  position: relative;
  max-width: 220px;

  // top: 62px !important;
  min-width: 220px;

  & > :global(.ant-menu) {
    border-radius: 4px;
  }

  :global {

    .ant-menu-item {
      display: flex;
      align-items: center;
      padding: 0 12px;

      &:active, &:focus, &:hover, &.ant-menu-item-active {
        background-color: var(--ant-primary-1);
      }

      a {
        color: inherit;
      }
    }

    .ant-menu-vertical.ant-menu-sub {

      .ant-menu-item:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .ant-menu-item-group-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      color: var(--gray-9);
    }

    .ant-menu-item-group-list {
      margin: 0 8px;
      min-width: 210px;
    }

    .ant-menu-title-content {
      display: flex;
      width: 100%;


      & > a {
        min-width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--gray-8);
        position: absolute;
        top: 0;
        left: 50px;
      }
    }

    .ant-menu-vertical .ant-menu-item {
      height: 32px;
      line-height: 32px;
    }
  }
}

.normalDropdownOverlay {
  position: fixed !important;
  top: 67px !important;
  min-width: 240px !important;
}

.moreMenu {
  max-height: calc(100vh - 100px);
  overflow-y: auto;

  :global {

    .ant-dropdown-menu-item-group-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      color: var(--gray-9);
    }

    .ant-menu-title-content {
      width: 100%;

      & > a {
        display: flex;
        justify-content: space-between;
        color: var(--gray-8);
      }
    }
  }
}

.normalDrawer {

  :global {

    .ant-drawer-content-wrapper .ant-drawer-body .ant-menu-item {
      justify-content: flex-start;

      .ant-menu-title-content {
        width: 100%;

        & > a {
          display: flex;
          justify-content: space-between;
        }
      }

      
    }

    .ant-menu-title-content{

      a{
        width: 100%;
        justify-content: space-between;
      }
    }

    .ant-menu-item{
      justify-content: flex-start;
    }

  }
}

.searchButton{
  margin: 0 15px;
  font-size: 18px;
  display: flex;
}

.searchModal{

  &:global{

    &.ant-modal {
      max-width: 100vw;

      .ant-modal-content{
        @media( max-width:768px ){
          padding: 10px;
        }
      }

      .ant-modal-close{
        border: 1px solid var(--gray-5);

        // border-radius: 50%;
        width: 30px;
        height: 30px;
      }

      .ant-modal-close-icon{
        font-size: 18px;
        line-height: 24px;

      }
    }
  }
}