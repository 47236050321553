@import '../../variables';
@import '../textTypography';
@import '../responsive';

.container {
  padding: 0 16px;

  @include responsive(mobile-large) {
    padding: 0 24px;
  }

  .container__title {
    display: flex;
    align-items: center;

    h4 {
      color: #d0d1d6;
      margin-bottom: 16px;
      margin-right: 8px;

      @include textTypography(Inter, 16px, normal, 600, 24px, 0, left);
    }

    .container__badge {
      color: white;
      background-color: $primary;
      border-radius: 4px;
      padding: 1px 8px;

      @include textTypography(Inter, 12px, normal, 400, 20px, 0, left);
    }
  }

  .container__menu {
    list-style: none;
    padding: 0;

    .container__item {
      color: #acaeb7;
      padding: 4px 0;

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);
    }

    .container__item__more {
      color: #acaeb7;
      padding: 4px 0;
      padding-top: 16px;

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);
    }
  }
}
