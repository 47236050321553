
.containerDropdown{
  box-sizing: border-box;
  padding: 24px 16px;
  background-color: white;
  box-shadow: var(--box-shadow-full-dropdown);
  width: calc(100vw - 15px);

  ul{
    margin: 0;
    padding: 0 0 0 14px;
    list-style: none;
  }

  li{
    margin-bottom: 8px;
  }

  li a{
    color: var(--grey-8);
    font-size: 16px;
    line-height: 24px;

    &:hover{
      color: var(--primary-color);
    }
  }

  :global{

    .ant-divider{
      font-size: 16px;
      line-height: 24px;
      color: var(--grey-8);
      text-transform: uppercase;
      margin-bottom: 24px;
    }
  }
}

.sectionTitle{
  font-size: 20px;
  font-weight: 600;
  color: var(--grey-9);
  line-height: 28px;
  margin: 0;
  margin-bottom: 12px;
  margin-left: 14px;
}

.footer{
  display: flex;
  justify-content: center;
}

.viewAllLink{
  display: inline-block;
  margin-top: 10px;
  margin-left: 14px;
  color: var(--primary-color);
  font-size: 16px;
  line-height: 24px;

  :global .anticon{
    transform: translateX(0);
    transition: all 0.3s;
  }

  
  &:hover{
    color: var(--primary-color);
    transition: all 0.3s;

    :global .anticon{
      transition: all 0.3s;
      transform: translateX(5px);
    }
  }

}